import axios from 'axios';
import { toast } from 'react-toastify';
import { base64ToObject, objectToBase64 } from '../encrypt';

const AuthApi = axios.create({
    baseURL: process.env.REACT_APP_AUTH_ENDPOINT,
    headers: {
        // 'Authorization': `Bearer ${ objectToBase64(process.env.REACT_APP_AUTH_APPTOKEN) }`
        'Authorization': `Bearer ${ process.env.REACT_APP_AUTH_APPTOKEN }`
    }
});

AuthApi.interceptors.request.use(
    (request) => {
        let encPayload = objectToBase64(request.data);
        let newRequest = {
            ...request, data: encPayload
            // ...request, data: request.data
        };
        // console.log(request.data);
        return newRequest;
    },
    (error) => {
        return Promise.reject(error);
    }
);

AuthApi.interceptors.response.use(
    (response) => {
        console.log(base64ToObject(response.data));
        return base64ToObject(response.data);
        // return response.data;
    },
    (error) => {
        let decResponse = base64ToObject(error.response.data);
        let errResponse = base64ToObject(error);

        // console.log(decResponse);
        // console.log(error);
        if (decResponse) {
            toast.warn(decResponse.message);
        } else {
            // console.log(errResponse);
            toast.error(errResponse.message === "Request failed with status code 500" ? "Internal Error!" : errResponse.message);
        }
        return Promise.reject(decResponse);
    }
);

export default AuthApi;
