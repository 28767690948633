
import React, { useState } from 'react';
import Alert from 'react-bootstrap/Alert';
import styled from 'styled-components';
import { useFetch } from '../api/useFetch';


const MainAlert = () => {
    const [showAlert, setShowAlert] = useState(true);
    const { data: allNotice } = useFetch('getNotices');

    return <>
        {
            allNotice?.data && <>
                {showAlert &&
                    <StyledAlert>
                        <Alert variant="warning" onClose={() => setShowAlert(false)} dismissible
                            className='py-2'
                        >
                            {allNotice?.data?.map(({ id, subject, body }) =>
                                <div
                                    key={id}
                                >
                                    <p className="fw-bold m-0">
                                        {subject}
                                    </p>
                                    <h6>
                                        {body}
                                    </h6>
                                </div>
                            )}
                        </Alert>
                    </StyledAlert>
                }
            </>
        }
    </>;
};

export default MainAlert;


const StyledAlert = styled.div`
   p{
        font-size:12px !important;
    }
   h6{
        font-size:10px !important;
        line-height: 1.5 !important;
    }

`;