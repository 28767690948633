import { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Api from '../../api/axios';
import { Button, Form, Input, PasswordInput } from '../../styles';
import { PASSWORD_REGEX } from '../../utils/data';
import { ICON_EYE_FILL, ICON_EYE_SLASH_FILL } from '../../utils/icons';
import AuthWrapper from '../../layout/AuthWrapper';
import CustomSelect from '../../utils/CustomSelect';
import AuthApi from '../../api/authAxios';


const Register = () => {
    let locationData = useLocation();

    const [formField, setformField] = useState({
        email: locationData?.state?.email || '',
        ref: locationData?.state?.ref || ''
    });
    const navigate = useNavigate();
    const [sending, setsending] = useState(false);
    const [pwordVal, setpwordVal] = useState(true);
    const [selectedCountry, setselectedCountry] = useState({ phoneCountryCode: '' });

    // Form Fields
    function handleChange(e) {
        setformField((inputs) => ({
            ...inputs,
            [e.target.name]: e.target.value,
        }));
    }
    // Form Field

    const [countries, setCountries] = useState([]);
    function getCountries() {
        Api.post(`/fetch.Countries`)
            .then((res) => {
                if (res.success === false) {
                } else {
                    setCountries(res.data);
                }
            });
    }
    useEffect(() => {
        getCountries();
    }, []);

    const allCountries = countries?.map(({ name, phoneCountryCode }, i) => {
        return (
            { label: name, value: name, phoneCountryCode }
        );
    });
    const gender = [
        { label: 'Male', value: 'Male' },
        { label: 'Female', value: 'Female' }
    ];


    // Form Validations]
    useEffect(() => {
        PASSWORD_REGEX.test(formField.pword) ? setpwordVal(false) : setpwordVal(true);
    }, [formField.pword]);
    // Form Validations

    // Submit Function
    function submitFn(e) {
        e.preventDefault();
        // if (pwordVal) {
        //     toast.warn("Password must be at least 7 characters and must contain a letter, a number and a special character");
        //     return;
        // }
        if (formField.pword !== formField.cpword) {
            toast.warn("Confirm password does not match password");
            return;
        }
        if (
            formField?.email &&
            formField.fname &&
            formField.lname &&
            formField.gender &&
            formField.pword &&
            formField.cpword &&
            formField.username &&
            // selectedCountry?.phoneCountryCode &&
            formField.phone
        ) {
            setsending(true);
            const data = {

                mail: formField?.email,
                fname: formField.fname,
                lname: formField.lname,
                phone: formField.phone,
                gender: formField.gender,
                pword: formField.pword,
                cpword: formField.cpword,
                username: formField.username,
                // ref: formField?.ref,
                // phoneCountryCode: "234",
                // country: "Nigeria",
                // phoneCountryCode: selectedCountry?.phoneCountryCode,
                // country: selectedCountry?.value,
            };
            AuthApi.post(`/completeRegister`, data)
                .then((res) => {
                    if (res.success === false) {
                        if (res.message === "Your email needs to be verified before you proceed.") {
                            toast.warn(res.message);
                            setTimeout(() => {
                                navigate('/register');
                            }, 10);
                            return;
                        }
                        toast.warn(res.message);
                        setsending(false);
                    } else {
                        setsending(false);
                        navigate(
                            "/register-success",
                            { state: formField?.email }
                        );
                        setTimeout(() => {
                            toast.success(`${ res.message }, Check your email to activate your account.`);
                        }, 10);
                    }
                })
                .catch((error) => {
                    setsending(false);
                });
        } else {
            setsending(false);
            toast.warn("Empty Fields!");
        }

    }

    // Submit Function

    //Show Password Function 
    const [eye, seteye] = useState(true);
    const [eye1, seteye1] = useState(true);

    return (
        <AuthWrapper
            title='Let’s get to know you'
            subTitle='Your information are all safe and secure'
        >

            <Form onSubmit={(e) => submitFn(e)} >

                <div className="row justify-content-center text-start">
                    <div className="col-md-6 ">
                        <Input
                            type="text"
                            name="fname"
                            placeholder="First Name"
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div className="col-md-6 ">
                        <Input
                            type="text"
                            name="lname"
                            placeholder="Last Name"
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div className="col-md-12 ">
                        <Input
                            type="email"
                            name="email"
                            placeholder="Email"
                            onChange={handleChange}
                            required
                            autoComplete='false'
                            value={formField?.email}
                        // disabled
                        />
                    </div>
                    <div className="col-md-12 ">
                        <Input
                            type="text"
                            name="username"
                            placeholder="Username"
                            onChange={handleChange}
                            required
                            autoComplete={'false'}
                        />
                    </div>
                    <div className="col-md-12 ">
                        <CustomSelect
                            placeholder={'Select Country'}
                            options={allCountries}
                            isSearchable={true}
                            onChange={(e) => setselectedCountry(e)}

                        />

                    </div>
                    <div className="col-3 col-md-4 col-lg-3 pe-0">
                        <Input
                            type="number"
                            name="phoneCountryCode"
                            placeholder="Country Code"
                            // onChange={handleChange}
                            // disabled
                            value={selectedCountry?.phoneCountryCode}
                        // required
                        />
                    </div>
                    <div className="col-9 col-md-8 col-lg-9 ps-0">
                        <Input
                            type="number"
                            name="phone"
                            placeholder="Phone Number"
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div className="col-md-12 "
                        title='Referral Code'>
                        <Input
                            type="text"
                            name="ref"
                            placeholder="Referral Code (Optional)"
                            onChange={handleChange}
                            value={formField.ref}
                            disabled={locationData?.state?.ref ? true : false}
                            autoComplete={'false'}
                        />
                    </div>
                    <div className="col-md-12 ">
                        <CustomSelect
                            options={gender}
                            isSearchable={true}
                            onChange={(e) => setformField((input) => ({ ...input, gender: e.value }))}
                            placeholder='Select Gender'
                            required
                        />
                    </div>

                    <div className="col-md-12">
                        <div className="input-group">
                            <PasswordInput
                                type={eye ? 'password' : 'text'}
                                name="pword"
                                placeholder="Password"
                                onChange={handleChange}
                                required
                                minLength="8"
                            />
                            <span
                                className="toggle-eye"
                                onClick={() => seteye(!eye)}
                            >
                                {eye ? ICON_EYE_FILL : ICON_EYE_SLASH_FILL}

                            </span>
                        </div>
                        <div className={`light-bold-bg fit-content pe-3 pt-1 br-sm mb-3 ${ pwordVal ? 'dark-text' : 'green-text' }`} style={{ fontSize: '10px' }}>
                            <ul>
                                <li>
                                    Password must have a capital letter
                                </li>
                                <li>
                                    Password must have a character
                                </li>
                                <li>
                                    Password must have a number.
                                </li>
                                <li>
                                    Password  must be at least 8 digits.
                                </li>
                            </ul>
                        </div>

                    </div>
                    <div className="col-md-12">
                        <div className="input-group">
                            <PasswordInput
                                type={eye1 ? 'password' : 'text'}
                                name="cpword"
                                placeholder="Password"
                                onChange={handleChange}
                                required
                                minLength="8"
                            />

                            <span
                                className="toggle-eye"
                                onClick={() => seteye1(!eye1)}
                            >
                                {eye1 ? ICON_EYE_FILL : ICON_EYE_SLASH_FILL}
                            </span>
                        </div>
                    </div>

                    <div className="col-md-12 text-center">
                        <div className="my-4">
                            <Button
                                pry
                                type="submit"
                                disabled={sending}
                                className="btn m-0 w-100"  >
                                {sending ? 'loading...' : "Create Free Account"}
                            </Button>
                        </div>
                        <div className="text-center dark-text">
                            Already have an account?
                            <Link to="/login" className="pry-text fw-bold"> Login </Link>

                        </div>
                    </div>
                </div>
            </Form>
        </AuthWrapper>

    );
};

export default Register;
