import { useEffect, useState, useRef } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import { Form, Input, Label, Button } from '../../styles';
import { EMAIL_REGEX } from '../../utils/data';
import AuthWrapper from '../../layout/AuthWrapper';
import AuthApi from '../../api/authAxios';

const EmailRegister = () => {
    const [formField, setformField] = useState('');
    const [emailValid, setemailValid] = useState(false);
    const navigate = useNavigate();
    const [sending, setsending] = useState(false);
    const [verifying, setverifying] = useState(false);
    const [authCode, setauthCode] = useState('');

    let params = useParams();
    const { id } = params;

    // Form Fields
    const [otp, setOtp] = useState(['', '', '', '', '']); // Initialize with empty strings for 5 OTP fields
    const otpFields = useRef([]); // Ref to store references to the OTP input fields

    const handleChange = (e, index) => {
        const value = e.target.value;
        if (value.length <= 1) {
            const otpCopy = [...otp];
            otpCopy[index] = value;
            setOtp(otpCopy);

            // Move focus to the next input field
            if (value !== '') {
                const nextIndex = Math.min(index + 1, otpFields.current.length - 1);
                otpFields.current[nextIndex].focus();
            }
        }
    };

    const handleKeyDown = (e, index) => {
        // Move focus to the previous input field on backspace/delete key press
        if (e.key === 'Backspace' || e.key === 'Delete') {
            if (otp[index] === '') {
                const prevIndex = Math.max(index - 1, 0);
                otpFields.current[prevIndex].focus();
            }
        }
    };

    // Form Fields
    useEffect(() => {
        EMAIL_REGEX.test(formField) ? setemailValid(true) : setemailValid(false);
    }, [formField]);


    useEffect(() => {
        setauthCode(`${ otp[0] }${ otp[1] }${ otp[2] }${ otp[3] }${ otp[4] }`);
    }, [otp[0], otp[1], otp[2], otp[3], otp[4]]);
    // Auth Fields


    // Submit Function
    function submitFn(e) {
        e.preventDefault();
        if (formField === '' && !emailValid) {
            toast.warn('enter valid email!');
            return;
        }
        if (otp[0] === '' || otp[1] === '' || otp[2] === '' || otp[3] === '' || otp[4] === '') {
            toast.warn('Input verification code!');
            return;
        }
        setverifying(true);
        const data = {
            authCode: authCode.toLocaleUpperCase(),
            mail: formField
        };

        AuthApi.post(`/activateMail`, data)
            .then((res) => {
                if (res.success === false) {
                    toast.warn(res.message);
                    setverifying(false);
                } else {
                    setverifying(false);
                    navigate(
                        `/register-complete`,
                        { state: { email: formField, ref: id } }
                    );
                    setTimeout(() => {
                        toast.success(res.message);
                    }, 10);
                }
            })
            .catch((error) => {
                setverifying(false);
            });
    }

    // Submit Function

    // Send Code Function
    function sendCode(e) {
        if (!formField && !emailValid) {
            toast.warn('enter valid email!');
            return;
        }
        e.preventDefault();
        setsending(true);
        const data = {

            mail: formField,
        };
        AuthApi.post(`/registerMail`, data)
            .then((res) => {
                if (res.success === false) {
                    toast.warn(res.message);
                    setsending(false);
                } else {
                    setsending(false);
                    setTimeout(() => {
                        toast.success(res.message);
                    }, 10);
                }
            })
            .catch((error) => {
                setsending(false);
            });
    }

    // Send Code Function

    return (

        <AuthWrapper
            title='Create Account'
            subTitle='Build your business with Escrow Transactions'
        >

            <div className="row justify-content-center text-start">
                <Form onSubmit={(e) => sendCode(e)}>
                    <div className="col-md-12 mb-5">
                        <Label htmlFor="email" className="">
                            Email:
                        </Label>
                        <Input
                            type="email"
                            id='email'
                            name="email"
                            placeholder="Email Address"
                            onChange={(e) => setformField(e.target.value)}
                            required
                        />
                        <Button
                            pry
                            type="submit"
                            disabled={sending}
                            className="btn w-100"

                        >
                            {sending ? 'loading...' : "Send Code"}
                        </Button>
                    </div>

                </Form>
                <div className="col-md-12">
                    <Label className="">
                        Verification Code:
                    </Label>

                    <VerificationInputGroup onSubmit={(e) => submitFn(e)} >
                        {otp.map((digit, index) => (
                            <Input
                                key={index}
                                type="text"
                                value={digit}
                                maxLength={1}
                                onChange={(e) => handleChange(e, index)}
                                onKeyDown={(e) => handleKeyDown(e, index)}
                                ref={(el) => (otpFields.current[index] = el)}
                                required
                            />
                        ))}
                        <Button
                            pry
                            type="submit"
                            disabled={verifying}
                            className="btn"

                        >
                            {verifying ? 'loading...' : "Verify"}
                        </Button>
                    </VerificationInputGroup>

                </div>

                <div className="col-md-12 text-center mt-4">

                    <div className="text-center dark-text">
                        Have an Account?
                        <Link to="/login" className="pry-text fw-bold"> Login </Link>
                    </div>
                </div>
            </div>
        </AuthWrapper>
    );
};

export default EmailRegister;

export const VerificationInputGroup = styled.form`
display:flex;
justify-content: space-between;
gap: 8px;
align-items: center;

input {
  padding: 12px 5px;
  text-align:center;
  text-transform: uppercase;
  font-weight: 800;
  font-size:20px;
}

button {
  height: 50px !important;
  margin: 8px 0 !important;
  margin-bottom: 1.5rem !important;
  }
`;